#{$project-name-space}login {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;

  &__logo {
    text-align: center;
    margin: 0 0 25px 0;
  }
}
