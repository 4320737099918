#{$project-name-space}month-table {
  &__title {
    text-transform: capitalize;
  }
  &__editable {
    display: flex;
    flex-direction: row;
    &__input.form-control {
      &:disabled {
        cursor: pointer;
      }
    }
    &__actions {
      display: none;
      button {
        background: transparent;
        border: none;
        cursor: pointer;
        .fa-check {
          color: green;
        }

        .fa-times {
          color: red;
        }
      }
    }
    &--edit {
      &__input.form-control {
        color: #495057;
        background-color: #fff;
        border: 1px solid #ced4da;
      }
      #{$project-name-space}month-table__editable__actions {
        display: flex;
      }
    }
  }
}
