@import "./styles/variables";
@import "./pages/login/login";
@import "./components/header/header";
@import "./pages/overview/overview";
@import "./pages/users/users";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

#{$project-name-space}wrapper {
  margin-top: 25px;
}
