@import "./_components/monthTable/month-table";

#{$project-name-space}overview {
  &__title {
    margin-bottom: 25px;
  }
  &__months {
    text-transform: capitalize;
  }
  &__data-holder {
    margin-top: 25px;
  }
}
